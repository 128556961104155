import { InferGetStaticPropsType, NextPage } from 'next';

import {
  FooterDocument,
  FooterQuery,
  FooterQueryVariables,
  FormDocument,
  FormQuery,
  FormQueryVariables,
  HomeCardsWithLinkDocument,
  HomeCardsWithLinkQuery,
  HomeCardsWithLinkQueryVariables,
  HomeHeroDocument,
  HomeHeroQuery,
  HomeHeroQueryVariables,
  HomeLargeImageDocument,
  HomeLargeImageQuery,
  HomeLargeImageQueryVariables,
  HomePartnersBlogSectionDocument,
  HomePartnersBlogSectionQuery,
  HomePartnersBlogSectionQueryVariables,
  HomeSeoDocument,
  HomeSeoQuery,
  HomeSeoQueryVariables,
  NavigationDocument,
  NavigationQuery,
  NavigationQueryVariables,
} from '@/api';
import { addApolloState, initializeApollo } from '@/api/apolloClient';
import { Homepage } from '@/views/homepage';

export const getStaticProps = async () => {
  const client = initializeApollo();

  // prefetch
  await client.query({ query: HomeSeoDocument });
  await client.query({ query: HomeHeroDocument });
  await client.query({ query: HomeCardsWithLinkDocument });
  await client.query({ query: HomeLargeImageDocument });
  await client.query({ query: HomePartnersBlogSectionDocument });
  await client.query({ query: FooterDocument });
  await client.query({ query: NavigationDocument });
  await client.query({ query: FormDocument });

  const { data: seoData } = await client.query<
    HomeSeoQuery,
    HomeSeoQueryVariables
  >({ query: HomeSeoDocument });

  const { data: heroData } = await client.query<
    HomeHeroQuery,
    HomeHeroQueryVariables
  >({
    query: HomeHeroDocument,
  });

  const { data: cardsData } = await client.query<
    HomeCardsWithLinkQuery,
    HomeCardsWithLinkQueryVariables
  >({
    query: HomeCardsWithLinkDocument,
  });

  const { data: largeImageSectionData } = await client.query<
    HomeLargeImageQuery,
    HomeLargeImageQueryVariables
  >({
    query: HomeLargeImageDocument,
  });

  const { data: partnersBlogSectionData } = await client.query<
    HomePartnersBlogSectionQuery,
    HomePartnersBlogSectionQueryVariables
  >({
    query: HomePartnersBlogSectionDocument,
  });

  const { data: formData } = await client.query<FormQuery, FormQueryVariables>({
    query: FormDocument,
  });

  const { data: footerData } = await client.query<
    FooterQuery,
    FooterQueryVariables
  >({ query: FooterDocument });

  const { data: headerData } = await client.query<
    NavigationQuery,
    NavigationQueryVariables
  >({ query: NavigationDocument });

  return addApolloState(client, {
    props: {
      heroData,
      cardsData,
      largeImageSectionData,
      seoData,
      formData,
      footerData,
      headerData,
      partnersBlogSectionData,
    },
  });
};

const Home: NextPage<InferGetStaticPropsType<typeof getStaticProps>> = ({
  heroData,
  cardsData,
  largeImageSectionData,
  seoData,
  formData,
  footerData,
  headerData,
  partnersBlogSectionData,
}) => (
  <Homepage
    seo={seoData?.home?.data?.attributes?.seo}
    hero={heroData.home?.data?.attributes?.heroVideoSection}
    cards={cardsData?.home?.data?.attributes?.imageCardsWithLink}
    largeImageSection={
      largeImageSectionData?.home?.data?.attributes?.largeImageSection
    }
    partnersBlogSection={
      partnersBlogSectionData?.home?.data?.attributes?.partnersBlogSection
    }
    formData={formData}
    footerData={footerData}
    headerData={headerData}
  />
);

export default Home;
