import {
  headingTagType,
  imageCaseStudyType,
  PartnersBlogSection,
  PartnersBlogSectionProps,
  useCaseType,
} from '@rimactechnology/rimac-ui-lib';

import { PartnersBlogSectionFragment } from '@/api';
import getCdlPublicId from '@/hooks/useCdlPublicId';

interface Props {
  titleCenter?: boolean;
  data?: PartnersBlogSectionFragment | null;
}

export const Partners = ({ data, titleCenter }: Props) => {
  const partnersProps: PartnersBlogSectionProps = {
    titleCenter,
    useCase: 'technology' as useCaseType,
    headingTag: 'h2' as headingTagType,
    heading: data?.title || undefined,
    anchor: data?.anchor || undefined,
    caseStudies: (data?.caseStudies?.data || []).map((caseStudy) => {
      const caseStudyAttributes = caseStudy?.attributes?.preview;
      return {
        image: {
          imgSrc:
            getCdlPublicId(
              caseStudyAttributes?.img_desktop?.data?.attributes?.url,
            ) || 'rimac/ui-lib/sample',
          alt: caseStudyAttributes?.alt || '',
          blurHash:
            caseStudyAttributes?.img_desktop?.data?.attributes?.blurhash ||
            'LAS?DV%M_3D%t7fQofj[~qM{4n-;',
        },
        tagName: caseStudyAttributes?.tag || undefined,
        tagType: (caseStudyAttributes?.tagColor ||
          'light') as imageCaseStudyType,
        title: caseStudyAttributes?.title || '',
        date: caseStudyAttributes?.date || '',
        link: `/case-study/${caseStudy?.attributes?.slug}`,
      };
    }),
    logos: (data?.logos || []).map((logo) => ({
      image: {
        imgSrc:
          getCdlPublicId(logo?.svg.data?.attributes?.url) ||
          'rimac/ui-lib/sample',
        alt: logo?.alt || '',
      },
    })),
    button: data?.button?.label
      ? {
          path: data?.button?.path || '',
          label: data?.button?.label || '',
          useCase: 'technology',
          openInNewTab: !!data?.button?.openInNewTab,
        }
      : undefined,
  };
  return <PartnersBlogSection {...partnersProps} />;
};
